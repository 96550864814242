<template>
  <layout :title="$route.name" :options="options">
    <div class="expande-horizontal centraliza wrap">
      <!-- <h1 class="text-center fonte orange--text">Bem Vindo</h1> -->
      <!-- <v-flex
        xs12
        md12
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6 mb-6'"
      >
        <ChartSaldo />
      </v-flex> -->

      <!-- <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <Saldo />
      </v-flex>
      <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <SaldoSaques />
      </v-flex>
      <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <SaldoTotalInvestido />
      </v-flex>
      <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <SaldoTotalPago />
      </v-flex> -->
      <v-flex xs12>
        <span class="fonte white--text px-3" style="font-size: 18pt;">Usuários</span>
      </v-flex>
      <v-flex xs12>
        <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-2'">
          <TotalUsers />
        </v-flex>
      </v-flex>
      <v-flex xs12>
        <span class="fonte white--text px-3" style="font-size: 18pt;">Binários Acumulados</span>
      </v-flex>
      <v-flex
        class="pa-2"
        :key="report._id"
        v-for="report in get_reports"
        xs12
        md3
      >
        <v-card style="border-radius: 12px;" color="#f2f2f2">
          <v-flex xs12>
            <div class="expande-horizontal wrap pt-1 pb-1">
              <v-flex xs12>
                <div class="expande-horizontal pb-1">
                  <v-list color="transparent" dense class="pa-0">
                    <v-list-item class="px-2">
                      <v-list-item-content>
                        <v-list-item-title class="fonte">
                        <span class="fonte" style="font-size: 12pt;">
                          {{ $helper.formataSaldo(report.valor) }}
</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="fonte">
                          {{ report.nome }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="fonte font-weight-light">
                          {{
                            $moment(report.updated_at).format(
                              "DD/MM/YYYY [às] HH:mm"
                            )
                          }}
                        </v-list-item-subtitle>
                        <!-- <v-list-item-subtitle class="orange--text">
                          {{ report.registros }} registros processados.
                        </v-list-item-subtitle> -->
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar size="31" class="mr-1" color="orange darken-1">
                          <v-icon dark>mdi-piggy-bank-outline</v-icon>
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </div>
              </v-flex>
            </div>
          </v-flex>
        </v-card>
      </v-flex>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Saldo from "./components/Saldo";
import SaldoDeIndicacao from "./components/SaldoDeIndicacao";
import PlanoDeCarreira from "./components/PlanoDeCarreira";
import Tokens from "./components/Tokens";
import Unilevel from "./components/Unilevel";
import Notificacoes from "./components/Notificacoes";
import ChartSaldo from "./components/ChartSaldo";
import Link from "./components/Link";
import PlanExpiresIn from "./components/PlanExpiresIn";
import TotalUsers from "./components/TotalUsers";
export default {
  components: {
    TotalUsers,
  },
  computed: {
    ...mapGetters(["getDashboard", "get_reports"])
  },
  methods: {
    ...mapActions([
      "listBalanceInvestment",
      "listBalanceToken",
      "listBalanceUnilevel",
      "listUnilevels",
      "list_reports"
    ])
  },
  created() {
    // this.listBalanceInvestment();
    // this.listBalanceToken();
    // this.listBalanceUnilevel();
    // this.listUnilevels();
    this.list_reports();
  }
};
</script>
