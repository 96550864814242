<template>
  <v-card
    hover
    elevate-on-hover
    dark
    @click="$router.push('/users')"
    height="120"
    color="#f2f2f2"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <span
        :style="`font-size: 36px; font-weight: bold; color: ${$theme.primary}`"
      >
        {{ getUsers.length }}
      </span>
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-cash-multiple</v-icon> -->
    </div>

    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div class="coluna">
        <!-- <span style="font-size: 8pt;" class="fonte"> Total em Vendas </span> -->
        <span class="fonteTitulo grey--text">USUÁRIOS NO SISTEMA</span>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-icon :color="$theme.primary"> mdi-account-outline </v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getUsers"])
  },
  methods: {
    ...mapActions(["listUsers"])
  },
  created() {
    this.listUsers();
  }
};
</script>
