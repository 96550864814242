<template>
  <v-card
    hover
    elevate-on-hover
    dark
    @click="$router.push('/unilevel')"
    height="120"
    color="#333"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap;"
  >
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <span :style="`font-size: 36px; font-weight: bold; color: ${$theme.primary}`"
        > {{getUnilevels | sum}} </span
      >
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-cash-multiple</v-icon> -->
    </div>

    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div class="coluna">
        <!-- <span style="font-size: 8pt;" class="fonte"> Total em Vendas </span> -->
        <span class="fonteTitulo grey--text">MINHA REDE</span>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-icon :color="$theme.primary"> mdi-network-outline </v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'getUnilevels'
        ])
    },
    filters: {
        sum(value) {
            const soma = value.level_1.length +
                value.level_2.length +
                value.level_3.length +
                value.level_4.length +
                value.level_5.length +
                value.level_6.length
            return soma
        }
    }
}
</script>